<template>
  <div>
    <c-card :title="`유해위험요인 관련사진`" class="cardClassDetailForm no-margin">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <!-- 사진 -->
          <c-upload-picture
            height="300px"
            :attachInfo="attachInfo"
            :editable="editable">
          </c-upload-picture>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      :title="popupParam.gridTitle"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :editable="editable"
      :usePaging="false"
      :isExcelDown="true"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="sopStepHazardFactorId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREMOVE" icon="delete" @btnClicked="deleteHazardFactor" />
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addHazardFactor" />
          <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveHazardFactor" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sopHazardFactorPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
        jobStepId: '',
        sopStepHazardId: '',
        mdmHazardFactorsId: '',
        gridTitle: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            style: 'width:150px',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridTitle() {
      return ''
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.sop.hazardFactor.list.url;
      this.saveUrl = transactionConfig.mdm.sop.hazardFactor.save.url;
      this.deleteUrl = transactionConfig.mdm.sop.hazardFactor.delete.url;
      // code setting
      this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmHazardFactorsId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopStepHazardId: this.popupParam.sopStepHazardId
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addHazardFactor() {
      this.grid.data.splice(0, 0, {
        sopStepHazardFactorId: uid(),  // 유해위험요인별 원인결과 일련번호
        mdmSopId: this.popupParam.mdmSopId,  // 안전작업 표준 일련번호
        jobStepId: this.popupParam.jobStepId,  // 작업단계 일련번호
        sopStepHazardId: this.popupParam.sopStepHazardId,  // 작업단계별 유해위험요인 일련번호
        mdmHazardFactorsId: this.popupParam.mdmHazardFactorsId,  // 유해위험요인 일련번호
        hazardOccurReason: '',  // 위험발생 원인
        hazardOccurResult: '',  // 위험발생 결과
        regUserId: this.$store.getters.user.userId,  // 등록자
        editFlag: 'C'
      })
    },
    saveHazardFactor() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.grid.data
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteHazardFactor() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: `유해위험요인 관련사진` },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c("c-upload-picture", {
                  attrs: {
                    height: "300px",
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: _vm.popupParam.gridTitle,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            editable: _vm.editable,
            usePaging: false,
            isExcelDown: true,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "sopStepHazardFactorId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "delete" },
                        on: { btnClicked: _vm.deleteHazardFactor },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addHazardFactor },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLSAVE", icon: "save" },
                        on: { btnClicked: _vm.saveHazardFactor },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }